export const AVAILABLE_TO_PROMISE = {
  TITLE: 'Available To Promise',
  DOWNLOAD: 'Export',
  BUTTON_ACTIONS: 'Quick Actions',
  VARIETY_ACTION: 'See Gaps by Variety',
  ACCUMULATE_INVENTORY: 'Combine days',
  GLOBAL_DATA_ACTION: 'Run Global Data',
  BUTTON_HIDE: 'Hide',
  BUTTON_SEARCH: 'Search',
  TOTAL: 'TOTAL',
  GROUP_RESULTS: 'Group Results by',
};
