import { availableToPromiseSlice } from '../../reducers/availableToPromise.slice';

export const {
  setStartDate,
  setEndDate,
  setFiltersInventoryTypes,
  setIsAccumulate,
  setFilterGlobalData,
  setGroupResultValues,
} = availableToPromiseSlice.actions;
