import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const availableToPromiseTableStyle = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: '600',
    margin: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '45%',
  },
  boxIcons: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end',
  },
  search: {
    width: '50%',
    marginBottom: '5px',
    marginTop: '5px',
  },
  tableCell: {
    fontSize: '12px',
    padding: '10px 10px',
    border: '1px solid #E0E0E0 ',
    whiteSpace: 'nowrap',
    background: '#F4F2EF',
    fontWeight: 600,
  },
  textCell: {
    height: '100%',
    marginLeft: '0px',
    marginRight: '0px',
    alingItem: 'center',
    boxSizing: 'border-box',
    border: '1px solid #E0E0E0',
    textIndent: '10px',
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
  },
  headerStyle: {
    backgroundColor: '#ECEFF1',
    fontSize: '13px',
    color: '#5c5c5c',
    fontWeight: 'bold',
    padding: '0px 0px 3px 0px',
    border: '1px solid #E0E0E0',
    fontFamily: 'Roboto, sans-serif',
    margin: '0px',
    width: '100%',
    textIndent: '10px',
  },
  borderCellEmpty: {
    height: '100%',
    marginLeft: '0px',
    marginRight: '0px',
    alingItem: 'center',
    boxSizing: 'border-box',
    BorderCollapse: 'collapse',
    textIndent: '10px',
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
  },
  textStyle: {
    height: '100%',
    borderTop: '1px solid #E0E0E0',
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
    textIndent: '10px',
  },
  textStyleEmpty: {
    height: '100%',
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
  },
});
