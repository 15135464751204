export enum MenuEnum {
  FORECAST_MANAGEMENT = 'Forecast Management',
  REVENUE_MANAGEMENT = 'Revenue Management',
  SALES_MANAGEMENT = 'Sales Management',
  WAREHOUSE_MANAGEMENT = 'Warehouse Management',
}

export enum REVENUE_MANAGEMENT_MENU_ENUM {
  AVALIBLE_TO_PROMISE = 'Available To Promise',
  ATP_SOURCE_DATA = 'ATP Source Data',
  SALES_ORDERS_ALLOCATION = 'Sales Orders Allocation',
  AGING_VARIEY = 'Aging By Variety',
  ALLOCATION_EXCEPTIONS = 'Allocation Exceptions',
  ALLOCATION_PRIORITIES = 'Allocation Priorities',
  ACTION_PLAN = 'Action Plan'
}

export const MENU = {
  '/revenue-management/sales-order-allocation':
    REVENUE_MANAGEMENT_MENU_ENUM.SALES_ORDERS_ALLOCATION,
  '/revenue-management/available-to-promise':
    REVENUE_MANAGEMENT_MENU_ENUM.AVALIBLE_TO_PROMISE,
    '/revenue-management/atp-source-data':
    REVENUE_MANAGEMENT_MENU_ENUM.ATP_SOURCE_DATA,
  '/revenue-management/aging-by-variety':
    REVENUE_MANAGEMENT_MENU_ENUM.AGING_VARIEY,
  '/revenue-management/action-plan':
    REVENUE_MANAGEMENT_MENU_ENUM.ACTION_PLAN,
};
