import { useState } from "react";
import { useAppSelector } from "@/store/hooks/storeHook"
import { RootState } from '@/store/interfaces/store.interfaces';
import { transformFiltersAction } from "@/store/utils/storeUtils";

export const UseSidebarActionPlan = () =>{
    const {
        startDate,
        endDate
    } = useAppSelector( (store : RootState)=> store.actionPlan)
    const [selectedValues, setSelectedValues] = useState({});

    return{
        startDate,
        endDate,
        selectedValues,
        setSelectedValues,
        filters: transformFiltersAction()
    }
}