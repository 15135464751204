import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './AtpSourceData.css';
import SidebarAtpSourceData from '@/components/revenueManagement/AvailableToPromise/SidebarAtpSourceData/SidebarAtpSourceData';
import Layout from '../../Layout/Layout';
import { ATP_SOURCE_DATA } from '@/shared/constantes/AtpSourceData';
import AtpSourceDataTab from '@/components/AtpSourceDataTab/AtpSourceDataTab';


const AtpSourceData = () => {
  return (
    <>
      <Layout
        title={ATP_SOURCE_DATA.TITLE}
        sidebar={SidebarAtpSourceData}
      >
        <AtpSourceDataTab />
      </Layout>
      
    </>
  );
};
export default AtpSourceData;