import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './AtpSourceDataTab.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const AtpSourceDataTab: React.FC = () => {
  const [value, setValue] = React.useState('one');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabItems = [
    { value: 'one', label: 'Real Inventory (available today)' },
    { value: 'five', label: 'Short term Inventory (+1 thru 5 days)' },
    { value: 'six', label: 'Future Inventory (+6 days forward)' },
  ];

  return (
    <div>
      <Box className="centered-box">
        <Tabs
          value={value}
          onChange={handleChange}
          className="tabs-root"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          {tabItems.map((item) => (
            <Tab 
            key={item.value} 
            value={item.value} 
            label={<span style={{ fontWeight: 'bold' }}>{item.label}</span>}/>
          ))}
        </Tabs>
      </Box>
    </div>
  );
};

export default AtpSourceDataTab;