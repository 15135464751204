import React, { useState } from 'react';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { Popover } from '@mui/material';
interface PopoverComponentProps {
  children: React.ReactNode;
  title: string;
  icon: React.ReactNode;
}

const PopoverComponent: React.FC<PopoverComponentProps> = ({ children, title, icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TooltipIcon title={title} icon={icon} handleClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </>
  );
};
export default PopoverComponent;
