import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseIOrdersAllocationState, ToggleRowExpansionParams } from './useSalesOrdersAllocationsState.interfaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { setSort } from '@/store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import {
  getSalesOrdersAllocation,
  downloadCsv,
} from '@/store/thunks/SalesOrdersAllocation/SalesOrdersAllocation.thunks';
import { convertNumber, sortData } from '@/shared/utils/numbersUtils';
import { handleRemainingTime } from '@/shared/utils/dateUtils';
import { salesOrdersAllocationColumns } from '@/shared/constantes/SalesOrdersAllocationColumns';
import { SalesOrdersAllocation } from '@/store/interfaces/salesOrdersAllocation.interfaces';

export const UseSalesOrdersAllocationState = (): UseIOrdersAllocationState => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(true);
  const {
    startDate,
    endDate,
    loading,
    sortBy,
    sortDirection,
    isFilterHGaps,
    filtersToSearch,
    isFilterGapsVariety,
    currentPage,
    allocation,
    totalPages,
  } = useAppSelector((store: RootState) => store.salesOrders);
  const allocations = allocation.allocations
  const totalAllocations = allocation.totalAllocations
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isExpandedAll, setExpandedAll] = useState(false);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const [page, setPage] = useState(1)
  let searchValue = useRef('');
  const salesOrdersColumns = salesOrdersAllocationColumns;

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleSort = (column: string) => {
    const newDirection =
      sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, newDirection }));
    dispatch(
      getSalesOrdersAllocation({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: prepareData(),
        currentPage: page,
        sortBy: column,
        sortDirection: newDirection
      })
    );
  };

  const handleShowSearchBar = () => setShowSearchBar(true);

  const handleShowBreakdowns = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number | null) => {
    if (event.ctrlKey) {
      setExpandedRow(index === expandedRow ? null : index);
      toggleRowExpansion(index, allocations)
    } else {
      setExpandedAll(!isExpandedAll)
      expandAllBreakdowns(index, allocations, isExpandedAll)
      setExpandedRow(null)
    }
  };

  const adjustedRowGetter = ({ index }: { index: number }) => {
    let offset = 0;
    for (let i = 0; i < allocations.length; i++) {
      const row = allocations[i];
      const expanded = expandedRows[i];
      if (index === offset) {
        return { ...row, isBreakdown: false, rowIndex: i };
      }
      if (expanded && index > offset && index <= offset + row.breakdowns.length) {
        return { ...row.breakdowns[index - offset - 1], isBreakdown: true };
      }
      offset += expanded ? row.breakdowns.length + 1 : 1;
    }
    return null;
  };


  const toggleRowExpansion = useCallback((index: any, allocations: any) => {
    setExpandedRows((prevExpandedRows = {}) => {
      const prevIndex: number = parseInt(Object.keys(prevExpandedRows)[0]);
      const isPrevRowExpanded = prevExpandedRows[prevIndex];
      if (prevIndex && index > prevIndex && isPrevRowExpanded) {
        const row = allocations[prevIndex];
        const breakdownsNumber = row.breakdowns.length;
        return { [index - breakdownsNumber]: true };
      }
      return { [index]: !prevExpandedRows[index] };
    });
  }, []);

  const adjustedRowCount = (allocations || []).reduce((count: number, row:any, index:any) => {
    const isExpanded = expandedRows[index] && row.breakdowns && row.breakdowns.length > 0;
    return count + (isExpanded ? row.breakdowns.length + 1 : 1);
  }, 0);

  const expandAllBreakdowns = useCallback((index: number | null, allocations: SalesOrdersAllocation[], isExpandedAll: boolean) => {
    setExpandedRows((prevExpandedRows = {}) => {
      if (isExpandedAll) {
        return {}
      } else {
        const allExpandedRows = { ...prevExpandedRows };
        allocations.forEach((row, i) => {
          if (row.boxComposition === "Assorted" && row.breakdowns.length > 0) {
            allExpandedRows[i] = true;
          }
        });
        return allExpandedRows;
      }
    });
  }, []);

  const prepareData = () => {
    let data;
    isFilterHGaps &&
      (data = {
        orderType: ['HO'],
      });
    return data = { ...data, ...filtersToSearch };
  }

  const handleDownloadCsv = () => {
    dispatch(
      downloadCsv({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: prepareData(),
        sortBy,
        sortDirection
      })
    );
  };

  const handleSearchValue = (data: string) => (searchValue.current = data);

  const getSalesOrderData = (page: number) => {
    dispatch(
      getSalesOrdersAllocation({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: prepareData(),
        currentPage: page,
        sortBy,
        sortDirection
      })
    );
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getSalesOrderData(currentPage)
  }

  const handleRoundOut = (dato:any) =>
    convertNumber(dato != undefined ? dato.toFixed(2) : 0);

  const handleChangePage = (newPage: number) => {
    getSalesOrderData(newPage)
    setPage(newPage)
  }

  useEffect(() => {
    if (filtersToSearch && Object.keys(filtersToSearch).length > 0) {
      if (isExpandedAll !== false) setExpandedAll(false);
      if (expandedRow !== null) setExpandedRow(null);
      if (Object.keys(expandedRows).length > 0) setExpandedRows({});
    }
  }, [filtersToSearch]);

  return {
    isExpandedAll,
    expandedRow,
    allocations: allocations,
    loading,
    sortBy,
    showSearchBar,
    visible,
    salesOrdersColumns,
    currentPage,
    adjustedRowCount,
    sortDirection,
    totalAllocations,
    totalPages,
    page,
    handleSort,
    handleShowSearchBar,
    handleShowBreakdowns,
    handleDownloadCsv,
    handleSubmit,
    handleSearchValue,
    handleRoundOut,
    adjustedRowGetter,
    handleChangePage
  };
};
