import { createSlice } from '@reduxjs/toolkit';
import { AtpSourceDataSlice } from '../interfaces/atpSourceData.interfaces';

export const initialState: AtpSourceDataSlice = {
  startDate: new Date(),
  endDate: new Date(),
  atp: [],
  filtersAtp: {
    group: [],
    datesType: [],
    category: [],
    variety: [],
    color: [],
    grade: [],
  },
  filtersInventoryTypes: {
    inventoryType: [],
  },
  isAccumulate: false,
  isFilterGlobalData: false,
};
export const atpSourceDataSlice = createSlice({
  name: 'AtpSourceDataSlice',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setFiltersInventoryTypes: (state, action) => {
      state.filtersInventoryTypes = action.payload;
    },
    setIsAccumulate: (state, action) => {
      state.isAccumulate = action.payload;
    },
    setFilterGlobalData: (state, action) => {
      state.isFilterGlobalData = action.payload;
    },
  },
});
export default atpSourceDataSlice.reducer;
