import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/store';
import { useAvailableToPromiseInterface } from './useAvailableToPromiseState.interfaces';
import data from '@/shared/data/available_to_promise_header.json';
import { IheadCell } from '@/components/Table/TableHeader.interfaces';
import {
  downloadCsv,
  getATP,
} from '@/store/thunks/AvailableToPromise/availableToPromise.thunks';
import { formatDate, handleFarmDay } from '@/shared/utils/dateUtils';
import { setSort } from '@/store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import { columnsDate, sortData } from '@/shared/utils/numbersUtils';
import inventoryTypes from '@/shared/data/inventory_type.json';
import inventryTypesAccumulated from '@/shared/data/inventory_type_accumulated.json';
import { setIsAccumulate } from '@/store/actions/availableToPromise/availableToPromise.actions';
export const UseAvailableToPromiseState =
  (): useAvailableToPromiseInterface => {
    const columns: IheadCell[] = data.data;
    const dispatch = useAppDispatch();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [headerDataTable, setheaderData] = useState<IheadCell[]>(columns);
    const {
      startDate,
      endDate,
      atpData,
      filtersInventoryTypes,
      isAccumulate,
      isFilterGlobalData,
      groupResultValue
    } = useAppSelector((store: RootState) => store.availableToPromise);
    const {
      sortBy,
      sortDirection,
      filtersToSearch,
      isFilterGapsVariety,
      loading,
    } = useAppSelector((store: RootState) => store.salesOrders);
    const headerIds = useRef<string[]>([]);
    const { atp, totalAtp, totalPages } = atpData;
    let searchValueATP = useRef('');
    const [visibleInventoryTypes, setVisibleInventoryTypes] = useState<useAvailableToPromiseInterface['visibleInventoryTypes']>({});
    const [totals, seTotals] = useState<number[]>([]);
    const [page, setPage] = useState(1)

    const handleTotalByDate = () => {
      const inventories = atp.map((atp) => atp.inventory);
      const newTotals = headerIds.current.map((date) => {
        return inventories.reduce((total, item) => {
          return (
            total +
            (isAccumulate
              ? item[date]?.accumulatedAtp || 0
              : item[date]?.atp || 0)
          );
        }, 0);
      });
      seTotals(newTotals);
      return newTotals;
    };

    useEffect(() => {
      headerIds.current = columnsDate(startDate, endDate);
    }, []);

    useEffect(() => {
      handleTotalByDate();
    }, [isAccumulate]);

    useEffect(() => {
      const formattedDates = columnsDate(startDate, endDate);
      const dateObject = formattedDates.map((date) => {
        const dateObject = date.split('-');
        return {
          field: date,
          headerName: `${handleFarmDay(date)} ${dateObject[1]}/${dateObject[2]}`,
          hoverName: `${dateObject[1]}/${dateObject[2]}`,
        };
      });
      const total = {
        field: 'total',
        headerName: 'TOT',
        hoverName: 'Total',
      };
      const newHederData = [...data.data, ...dateObject, total];
      setheaderData(newHederData);
      headerIds.current = formattedDates;
      handleTotalByDate();
    }, [atp]);

    const handleDownloadATPCSV = () => {
      let newFilters = { ...filtersToSearch };
      if (filtersInventoryTypes.inventoryType.length > 0) {
        newFilters = {
          ...newFilters,
          inventoryType: filtersInventoryTypes.inventoryType,
        };
      }
      dispatch(
        downloadCsv({
          startDate,
          endDate,
          data: newFilters,
          missingStems: isFilterGapsVariety,
          runGlobalData: isFilterGlobalData,
          groupResult: groupResultValue,
          accumulate: isAccumulate,
        })
      );
    };

    const handleSort = (column: string) => {
      const newDirection =
        sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
      dispatch(setSort({ column, newDirection }));
      dispatch(getATP(
        {
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isFilterGapsVariety,
          data: filtersToSearch,
          currentPage: page,
          sortBy: column,
          sortDirection: newDirection
        }
      ));
    };

    const handleSearchValue = (data: string) => (searchValueATP.current = data);

    const handleSubmit: useAvailableToPromiseInterface['handleSubmit'] = (event) => {
      event.preventDefault();
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isFilterGapsVariety,
          data: filtersToSearch,
          currentPage: 1,
        })
      );
    };

    const handleTotal = (inventoryType: any, row: any) => {
      const totalField = row.total[inventoryType]
      return totalField;
    };

    useEffect(() => {
      let newVisibility: { [key: string]: boolean } = {};
      if (
        (filtersInventoryTypes.inventoryType &&
          filtersInventoryTypes.inventoryType.length === 0) ||
        filtersInventoryTypes.inventoryType === undefined
      ) {
        if (isAccumulate) {
          inventryTypesAccumulated.forEach(
            (item) => (newVisibility[item.inventorytype] = true)
          );
        } else {
          inventoryTypes.forEach(
            (item) => (newVisibility[item.inventorytype] = true)
          );
        }
      } else {
        filtersInventoryTypes.inventoryType &&
          filtersInventoryTypes.inventoryType.forEach(
            (item) => (newVisibility[item] = true)
          );
      }
      setVisibleInventoryTypes(newVisibility);
    }, [filtersInventoryTypes, isAccumulate]);

    const handleGetPropAccumulate = (inventoryType: string) => {
      const accumulatedInventoryType =
        inventoryType.charAt(0).toUpperCase() + inventoryType.slice(1);
      return isAccumulate
        ? `accumulated${accumulatedInventoryType}`
        : inventoryType;
    };

    const handleChangeAccumulate = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => dispatch(setIsAccumulate(event.target.checked));

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isFilterGapsVariety,
          data: filtersToSearch,
          currentPage: newPage,
          sortBy,
          sortDirection
        })
      );
      setPage(newPage)
    }

    return {
      loading,
      showSearchBar,
      setShowSearchBar,
      headerDataTable,
      atp,
      headerIds: headerIds.current,
      totals: totals,
      sortBy,
      sortDirection,
      columns,
      inventoryColumns: isAccumulate
        ? inventryTypesAccumulated
        : inventoryTypes,
      isAccumulate,
      visibleInventoryTypes,
      totalAtp,
      totalPages,
      handleDownloadATPCSV,
      page,
      handleSort,
      handleSearchValue,
      handleSubmit,
      handleTotal,
      handleGetPropAccumulate,
      handleChangeAccumulate,
      handleChangePage
    };
  };
