import { FiltersATP } from '../interfaces/filtersATP.interfaces';
import { FiltersAllocationData } from '../interfaces/filtersAllocation.interfaces';
import { ITransformFilters } from '../interfaces/store.interfaces';

//Allocation orders
export const transformFilters = (
  data: FiltersAllocationData
): ITransformFilters[] => {
  return [
    {
      id: 'accountName',
      filtername: 'Account Name',
      componentType: 'Checkbox',
      valueOptions: [...(data.accountName || [])].sort(),
    },
    {
      id: 'market',
      filtername: 'Market',
      componentType: 'Checkbox',
      valueOptions: [...(data.market || [])].sort(),
    },
    {
      id: 'guidance',
      filtername: 'Guidance',
      componentType: 'Checkbox',
      valueOptions: [...(data.guidance || [])].sort(),
    },
    {
      id: 'accountOwner',
      filtername: 'Account Owner',
      componentType: 'Checkbox',
      valueOptions: [...(data.accountOwner || [])].sort(),
    },
    {
      id: 'orderType',
      filtername: 'Order Type',
      componentType: 'Checkbox',
      valueOptions: [...(data.orderType || [])].sort(),
    },
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
    },
    {
      id: 'boxComposition',
      filtername: 'Assorted / Solid',
      componentType: 'Checkbox',
      valueOptions: [...(data.boxComposition || [])].sort(),
    },
  ];
};

export const transformFiltersATp = (
  data: FiltersATP,
  isAccumulate: boolean,
  groupResultValue: string[]
) => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('category'),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('variety'),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('color'),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('grade'),
    },
    {
      id: 'inventoryType',
      filtername: 'Inventory',
      componentType: 'Checkbox',
      valueOptions: isAccumulate
        ? [
            'On-Hand Inventory',
            'Forecasted Exp. Production',
            'Expected Inventory',
            'Sales Orders',
            'Expiring Inventory',
            'Final On-Hand Inventory',
            'Final Forecasted Exp. Production',
            'ATP Inventory',
          ].sort()
        : [
            'On-Hand Inventory',
            'Forecasted Exp. Production',
            'Expected Inventory',
            'Sales Orders',
            'ATP Inventory',
          ].sort(),
    },
  ];
};

export const transformFiltersAtpSourceData = (data: FiltersATP) => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
    },
  ];
};

export const transformFiltersAging = () => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'rotation',
      filtername: 'Rotation Days',
      componentType: 'Checkbox',
      valueOptions: ['1', '2', '3', '4'].sort(),
    },
  ];
};

export const filterData = (data: object) => {
  const newData = Object.fromEntries(
    Object.entries(data).filter((_, valor) => {
      return !(Array.isArray(valor) && valor.length === 0);
    })
  );
  return newData;
};

export const transformFiltersAction = () => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: ['1', '2', '3', '4'].sort(),
    },
  ];
};
