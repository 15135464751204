import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { UseISalesOrderAllocationState } from './useSalesOrderAllocation.interfaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { getFiltersAllocationData } from '@/store/thunks/SalesOrdersAllocation/SalesOrdersAllocation.thunks';
import { RootState } from '@/store/store';

export const UseSalesOrderAllocationState =
  (): UseISalesOrderAllocationState => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch()
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const {
      startDate,
      endDate,
    } = useAppSelector((store: RootState) => store.salesOrders);
    
    useEffect(()=>{
      dispatch(getFiltersAllocationData({ startDate, endDate }));
    },[])

    return {
      openModal,
      handleCloseModal,
      handleOpenModal,
    };
  };
