import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { UseIAtpSourceDataState } from './useSidebarAtpSourceData.interfaces';
import {
  setEndDate,
  setFilterGlobalData,
  setFiltersInventoryTypes,
  setStartDate,
} from '@/store/actions/atpSourceData/atpSourceData.actions';

import { useEffect, useState } from 'react';
import { transformFiltersAtpSourceData } from '@/store/utils/storeUtils';
import {
  setFilterGapsByVariety,
  setFiltersToSearch,
} from '@/store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
interface SelectedValues {
  inventoryType: string[];
  [key: string]: any;
}

export const UseSidebarAtpSourceDataState =
  (): UseIAtpSourceDataState => {
    const dispatch = useAppDispatch();
    const [selectedValues, setSelectedValues] = useState<SelectedValues>({
      inventoryType: [],
    });
    const [valuesToInventoryType, setValuesToInventoryType] = useState({
      inventoryType: [],
    });
    const {
      startDate,
      endDate,
      filtersAtp,
      filtersInventoryTypes,
      isFilterGlobalData,
    } = useAppSelector((store: RootState) => store.availableToPromise);
    const { filtersToSearch } = useAppSelector(
      (store: RootState) => store.salesOrders
    );
    const handleSetStartDate = (startDate:Date) => dispatch(setStartDate(startDate));
    const handleSetEndDate = (endDate:Date) => dispatch(setEndDate(endDate));
    const handleMaxDate = () => {
      const date = new Date();
      date.setDate(date.getDate() + 14);
      return date;
    };
    

    useEffect(() => {
      let data;
      data = { ...selectedValues };
      const { inventoryType, ...rest } = selectedValues;
      if (inventoryType === undefined) {
        setSelectedValues({ inventoryType: [] });
      }
      if (
        !(
          selectedValues.inventoryType &&
          selectedValues.inventoryType.length !==
            (filtersInventoryTypes.inventoryType &&
              filtersInventoryTypes.inventoryType.length)
        )
      ) 
      dispatch(setFiltersToSearch(rest));
      dispatch(setFiltersInventoryTypes({ inventoryType: inventoryType }));
    }, [selectedValues]);

    const handleRunGlobalData = () => {
      dispatch(setFilterGlobalData(!isFilterGlobalData));
      dispatch(setFilterGapsByVariety(false));
    };

    return {
      isFilterGlobalData,
      filters: transformFiltersAtpSourceData(filtersAtp),
      endDate,
      startDate,
      valuesToInventoryType,
      handleSetStartDate,
      handleSetEndDate,
      handleMaxDate,
      selectedValues,
      setSelectedValues,
      setValuesToInventoryType,
      handleRunGlobalData,
      isFilterGapsVariety: false, 
      handleFilterGapsVariety: () => {}, 
      handleATP: () => {}, 
    };
  };
