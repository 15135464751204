import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const TableHeaderStyles = createNamedStyles({
  tableCell: {
    padding: '15px 10px',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    alignContent: 'start',
    lineHeight: '15px',
    border: '1px solid #E0E0E0 ',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: '#ECEFF1',
    // cursor: 'pointer',
  },
});
