import React from 'react';
import FilterComponent from '@/components/Filters/FilterComponent';
import { ATP_SOURCE_DATA } from '@/shared/constantes/AtpSourceData';
import { UseSidebarAtpSourceDataState } from './state/useSidebarAtpSourceDataState';
import Dates from '@/components/Dates/Dates';
import { Box, Typography, Link } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const sidebarAtpSourceDataStyles = createNamedStyles({
  sidebarBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    textAlign: 'start',
    margin: '0px 20px 20px',
  },
  textActions: {
    fontWeight: 700,
    fontSize: '14px',
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#DCDCDE',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  box: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  text: {
    color: '#3E3E45',
    fontSize: '13px',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: '#F4F2EF',
    ':hover': {
      color: theme.palette.primary.main,
    },
  },
});

const SidebarAtpSourceData = () => {
  const {
    filters,
    startDate,
    endDate,
    isFilterGlobalData,
    selectedValues,
    setSelectedValues,
    handleSetStartDate,
    handleSetEndDate,
    handleATP,
    handleRunGlobalData,
  } = UseSidebarAtpSourceDataState();
  return (
    <>
      <Dates
        startDate={startDate}
        endDate={endDate}
        handleSetStartDate={handleSetStartDate}
        handleSetEndDate={handleSetEndDate}
        handleSearch={handleATP}
      />
      <Box sx={sidebarAtpSourceDataStyles.sidebarBox}>
        <Typography sx={sidebarAtpSourceDataStyles.textActions}>
          {ATP_SOURCE_DATA.BUTTON_ACTIONS}
        </Typography>
        <Box sx={sidebarAtpSourceDataStyles.box}>
          <Link onClick={handleRunGlobalData}>
            <Typography sx={sidebarAtpSourceDataStyles.text}>
              {' '}
              {ATP_SOURCE_DATA.GLOBAL_DATA_ACTION}
            </Typography>
          </Link>
          {isFilterGlobalData && (
            <ClearOutlinedIcon
              onClick={handleRunGlobalData}
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
      </Box>
      <FilterComponent
        data={filters}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        showHeader={true}
      />
    </>
  );
};
export default SidebarAtpSourceData;
