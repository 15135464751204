import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook";
import { getFiltersATP } from "@/store/thunks/AvailableToPromise/availableToPromise.thunks";
import { RootState } from "@/store/store";

export const useAvailableToPromiseState = () => {
    const dispatch = useAppDispatch();
    const {
        startDate,
        endDate,
      } = useAppSelector((store: RootState) => store.availableToPromise);

    useEffect(()=>{
        dispatch(getFiltersATP({ startDate, endDate }));
    }, [])
    
}