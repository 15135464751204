import React from 'react';
import {
  Grid,
  AppBar,
  Button,
  Toolbar,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Avatar,
  ListItemIcon,
  Divider,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useHeaderState } from './state/useHeaderState';
import { headerStyles as styles } from './Header.styles';
import { AtlasIcon, LineIcon, RosaprimaIcon } from '../../shared/icons/Icons';
import HoverMenu from '../HoverMenu/HoverMenu';

const Header = () => {
  const { handleRedirect, handleLogout, open, handleOpenMenu, activeAccount } =
    useHeaderState();

  return (
    <AppBar sx={styles.headerBar}>
      <Toolbar sx={styles.headerToolbar}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={styles.headerLogos}>
            <AtlasIcon />
            <LineIcon />
            <RosaprimaIcon />
          </Grid>
          <Grid item xs={8} sx={styles.headerAlign}>
            {activeAccount && (
              <Box sx={{ paddingLeft: '16px' }}>
                <HoverMenu />
              </Box>
            )}
          </Grid>
          <Grid item xs={1} sx={styles.headerAlign}>
            {activeAccount ? (
              <Box>
                <IconButton
                  data-testid="handleOpenMenuButton"
                  onClick={() => handleOpenMenu(true)}
                  sx={{ p: 0 }}
                >
                  <Avatar alt="Remy Sharp" data-testid='Avatar' />
                </IconButton>
                <Menu
                  data-testid="account-menu"
                  open={open}
                  onClose={() => handleOpenMenu(false)}
                  onClick={() => handleOpenMenu(false)}
                  PaperProps={{
                    elevation: 0,
                    sx: styles.headerMenu,
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem>
                    <Avatar /> Profile
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                variant="outlined"
                sx={styles.headerBSignIn}
                onClick={handleRedirect}
              >
                Sign-In
              </Button>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
