import React from 'react';
import FilterComponent from '@/components/Filters/FilterComponent';
import { AVAILABLE_TO_PROMISE } from '@/shared/constantes/AvailableToPromise';
import { UseSidebarAvailableToPromiseState } from './state/useSidebarAvailableToPromiseState';
import Dates from '@/components/Dates/Dates';
import { Box, Typography, Link, Button, FormControlLabel, FormGroup } from '@mui/material';
import { sidebardAvailableToPromiseStyles as styles } from './SidebarAvailableToPromise.styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Checkbox } from '@mui/material';

const SidebarAvailableToPromise = () => {
  const {
    filters,
    startDate,
    endDate,
    isFilterGapsVariety,
    isFilterGlobalData,
    selectedValues,
    setSelectedValues,
    handleSetStartDate,
    handleSetEndDate,
    handleFilterGapsVariety,
    handleATP,
    handleRunGlobalData,
    groupResultValue,
    handleSetGroupResult,
    handleReset,
  } = UseSidebarAvailableToPromiseState();
  return (
    <>
      <Dates
        startDate={startDate}
        endDate={endDate}
        handleSetStartDate={handleSetStartDate}
        handleSetEndDate={handleSetEndDate}
        handleSearch={handleATP}
      />
      <Box sx={styles.sidebarBox}>
        <Typography sx={styles.textActions}>
          {AVAILABLE_TO_PROMISE.BUTTON_ACTIONS}
        </Typography>
        <Box sx={styles.box}>
          <Link onClick={handleRunGlobalData}>
            <Typography sx={styles.text}>
              {' '}
              {AVAILABLE_TO_PROMISE.GLOBAL_DATA_ACTION}
            </Typography>
          </Link>
          {isFilterGlobalData && (
            <ClearOutlinedIcon
              onClick={handleRunGlobalData}
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
        <Box sx={styles.box}>
          <Link component="button" onClick={() => handleFilterGapsVariety('')}>
            <Typography sx={styles.text}>
              {AVAILABLE_TO_PROMISE.VARIETY_ACTION}
            </Typography>
          </Link>
          {isFilterGapsVariety && (
            <ClearOutlinedIcon
              onClick={() => handleFilterGapsVariety('')}
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ margin: '5px', backgroundColor: 'white', padding: '2px' }}>

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button sx={{
          color: "#5c5c5c",
          fontSize: '13px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          textTransform: 'capitalize',
        }} onClick={handleReset}>
          Reset
        </Button>
      </Box>
      <Box sx={styles.sidebarBox}>
        <Typography sx={styles.textActions}>
          {AVAILABLE_TO_PROMISE.GROUP_RESULTS}
        </Typography>
        <Box sx={styles.box}>
          <FormGroup>
            {
              filters.slice(0, -1).map((item, index) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={<Checkbox />}
                  label={<Typography sx={{ fontSize: '13px' }}>{item.filtername}</Typography>}
                  checked={groupResultValue.includes(item.id)}
                  onChange={(event) => handleSetGroupResult(event, item.id)}
                />
              ))
            }
          </FormGroup>
        </Box>
      </Box>
      <FilterComponent
        data={filters}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        showHeader={true}
      />
    </>
  );
};
export default SidebarAvailableToPromise;
