import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const filterStyle = createNamedStyles({
  text: {
    color: theme.palette.secondary.main,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  filterSummary: {
    backgroundColor: '#927C4E',
    color: theme.palette.secondary.main,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#927C4E',
    cursor: 'pointer',
    marginBottom: '12px',
  },
  accordionDetails: {
    backgroundColor: '#F4F2EF',
    fontSize: '13px',
  },
  textButton: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
  searchBar: {
    width: '100%',
    height: '1%',
    backgroundColor: 'white',
    marginBottom: '10px',
    color: '#BABABE',
  },
});
