import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { handleRemainingTime } from '@/shared/utils/dateUtils';

const Timer = () => {
    const [untilTime, setUntilTime] = useState({ hours: 0, minutes: 0 });

    useEffect(() => {
        const updateRemainingTime = () => {
            const remainingTime = handleRemainingTime();
            setUntilTime(remainingTime);
        };

        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{fontSize:"11px", display: "flex", alignItems:"center", justifyContent:"end"}}>Time until next update: {untilTime.hours} h {untilTime.minutes} m </Box>
    )
}
export default Timer;