import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import TableHeader from '@/components/Table/TableHeader';
import { UseAgingByVarietyTableState } from './state/UseAgingByVarietyTableState';
import {
  Download,
  EditIcon,
  SearchBarIcon,
  ShowColumns,
} from '@/shared/icons/Icons';
import { AGING_BY_VARIETY } from '@/shared/constantes/AgingByVariety';
import SearchBar from '@/components/SearchBar/SearchBar';
import HideColumns from '@/components/HideColumns/HideColumns';
import { agingByVarietyStyles as styles } from './AgingByVariety.styles';
import TooltipIcon from '@/components/Tooltip/TooltipIcon';
import PopoverComponent from '@/components/PopoverComponent/PopoverComponent';
import RotationDaysPopover from '@/components/RotationDaysPopover/RotationDays';

const AgingByVarietyTable = () => {
  const {
    columns,
    loading,
    aging,
    showSearchBar,
    editColumn,
    editIndex,
    formData,
    sortBy,
    setShowSearchBar,
    handleSave,
    handleChange,
    handleDoubleClick,
    handleSort,
  } = UseAgingByVarietyTableState();

  const [visibleColumns, setVisibleColumns] = useState<{ [key: string]: boolean }>(() => {
    const initialVisibility = columns.reduce<{ [key: string]: boolean }>((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {});
    initialVisibility;

    return initialVisibility;
  });

  const handleToggleColumn = (columnId: string) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  return (
    <>
      <Box sx={styles.box}>
        <Box sx={styles.text}>
          <Box>{`${aging && aging.length} results`} </Box>
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar handleSearchValeu={() => {}} handleSubmit={() => {}} />
            </Box>
          ) : (
            <TooltipIcon
              title={AGING_BY_VARIETY.BUTTON_SEARCH}
              handleClick={() => setShowSearchBar(true)}
              icon={<SearchBarIcon />}
            />
          )}
          <PopoverComponent
            children={
              <HideColumns
                columnsName={columns}
                handleToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
              />
            }
            title={AGING_BY_VARIETY.BUTTON_HIDE}
            icon={<ShowColumns />}
          />
          <TooltipIcon
            title={AGING_BY_VARIETY.DOWNLOAD}
            icon={<Download />}
            handleClick={() => {}}
          />
        </Box>
      </Box>
      {!loading ? (
        <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHeader
              headCell={columns}
              visibleColumns={visibleColumns}
             // isCheckbox={true}
              handleSort={handleSort}
              sortBy={sortBy}
            ></TableHeader>
            <TableBody>
              {aging &&
                aging.length > 0 &&
                aging.map((row, index) => {
                  return (
                    <>
                      <TableRow>
                        {visibleColumns['category'] && (
                          <TableCell>{row.category}</TableCell>
                        )}
                        {visibleColumns['variety'] && (
                          <TableCell>{row.variety}</TableCell>
                        )}
                        {visibleColumns['color'] && (
                          <TableCell>{row.color}</TableCell>
                        )}
                        {visibleColumns['rotationDays'] && (
                          <TableCell
                            onDoubleClick={() =>
                              handleDoubleClick(index, 'rotationDays', row)
                            }
                          >
                            {editIndex === index &&
                            editColumn === 'rotationDays' ? (
                              <TextField
                                value={formData.rotationDays}
                                onChange={(e) =>
                                  handleChange(e, 'rotationDays')
                                }
                                onBlur={handleSave}
                                autoFocus
                                size="small"
                                variant="standard"
                                sx={{ width: '60px' }}
                              />
                            ) : (
                              row.rotationDays
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default AgingByVarietyTable;
