// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker {
  width: 90%;
  color: #3e3e45;
  border: 1px solid #cececf;
  border-radius: 4px;
  padding: 4px;
  font-size: 13px;
  z-index: 100;
}

.react-datepicker {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 12px;
}

.react-datepicker-popper {
  padding-left: 5%;
  z-index: 1000;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 0px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/containers/revenueManagement/AtpSourceData/AtpSourceData.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,YAAY;AACd;;AAEA;EACE;;;;;;;;;;;cAWY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,gCAAgC;EAChC,8BAA8B;EAC9B,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".custom-datepicker {\n  width: 90%;\n  color: #3e3e45;\n  border: 1px solid #cececf;\n  border-radius: 4px;\n  padding: 4px;\n  font-size: 13px;\n  z-index: 100;\n}\n\n.react-datepicker {\n  font-family:\n    system-ui,\n    -apple-system,\n    BlinkMacSystemFont,\n    'Segoe UI',\n    Roboto,\n    Oxygen,\n    Ubuntu,\n    Cantarell,\n    'Open Sans',\n    'Helvetica Neue',\n    sans-serif;\n  font-size: 12px;\n}\n\n.react-datepicker-popper {\n  padding-left: 5%;\n  z-index: 1000;\n}\n\n.react-datepicker__header {\n  text-align: center;\n  background-color: #fff;\n  border-bottom: 0px solid #aeaeae;\n  border-top-left-radius: 0.3rem;\n  padding: 8px 0;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
