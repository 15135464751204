import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { UseIAvailableToPromiseState } from './useSidebarAvailableToPromise.interfaces';
import {
  setEndDate,
  setFilterGlobalData,
  setFiltersInventoryTypes,
  setStartDate,
  setGroupResultValues
} from '@/store/actions/availableToPromise/availableToPromise.actions';
import {
  getATP,
  getFiltersATP,
} from '@/store/thunks/AvailableToPromise/availableToPromise.thunks';
import { useEffect, useState } from 'react';
import { transformFiltersATp } from '@/store/utils/storeUtils';
import {
  setFilterGapsByVariety,
  setFiltersToSearch,
} from '@/store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
interface SelectedValues {
  inventoryType: string[];
  [key: string]: any;
}

export const UseSidebarAvailableToPromiseState =
  (): UseIAvailableToPromiseState => {
    const dispatch = useAppDispatch();
    const [selectedValues, setSelectedValues] = useState<SelectedValues>({
      inventoryType: [],
    });
    const [valuesToInventoryType, setValuesToInventoryType] = useState({
      inventoryType: [],
    });
    const {
      startDate,
      endDate,
      filtersAtp,
      filtersInventoryTypes,
      isAccumulate,
      isFilterGlobalData,
      groupResultValue,
    } = useAppSelector((store: RootState) => store.availableToPromise);
    const { isFilterGapsVariety, filtersToSearch } = useAppSelector(
      (store: RootState) => store.salesOrders
    );
    const handleSetStartDate = (startDate: Date) => dispatch(setStartDate(startDate));
    const handleSetEndDate = (endDate: Date) => dispatch(setEndDate(endDate));
    const handleMaxDate = () => {
      const date = new Date();
      date.setDate(date.getDate() + 14);
      return date;
    };
    const handleATP = () => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          missingStems: isFilterGapsVariety,
          data: { ...filtersToSearch },
          runGlobalData: isFilterGlobalData,
          currentPage: 1,
          groupResult: groupResultValue
        })
      );
      dispatch(getFiltersATP({ startDate, endDate }));
    };
    useEffect(() => {
      //dispatch(getFiltersATP());
      dispatch(setFiltersInventoryTypes({ inventoryType: [] }));
    }, []);

    useEffect(() => {
      const { inventoryType, ...rest } = selectedValues;
      if (inventoryType === undefined) {
        setSelectedValues({ inventoryType: [] });
      }
      if (
        !(
          selectedValues.inventoryType &&
          selectedValues.inventoryType.length !==
          (filtersInventoryTypes.inventoryType &&
            filtersInventoryTypes.inventoryType.length)
        )
      ) {
        dispatch(
          getATP({
            startDate,
            endDate,
            dispatch,
            data: rest,
            missingStems: isFilterGapsVariety,
            currentPage: 1,
            runGlobalData: isFilterGlobalData,
            groupResult: groupResultValue
          })
        );
      }
      dispatch(setFiltersToSearch(rest));
      dispatch(setFiltersInventoryTypes({ inventoryType: inventoryType }));
    }, [selectedValues]);

    const handleFilterGapsVariety = () => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          missingStems: !isFilterGapsVariety,
          data: { ...filtersToSearch },
          currentPage: 1,
          groupResult: groupResultValue
        })
      );
      dispatch(setFilterGapsByVariety(!isFilterGapsVariety));
      dispatch(setFilterGlobalData(false));
    };

    const handleRunGlobalData = () => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          runGlobalData: !isFilterGlobalData,
          currentPage: 1
        })
      );
      dispatch(setFilterGlobalData(!isFilterGlobalData));
      dispatch(setFilterGapsByVariety(false));
    };

    const handleSetGroupResult = (event: React.SyntheticEvent<Element, Event>, item: string) => {
      const target = event.target as HTMLInputElement;
      dispatch(setFilterGlobalData(false));
      const newGroupResultValue = target.checked
        ? [...groupResultValue, item]
        : groupResultValue.filter(value => value !== item);
      dispatch(setGroupResultValues(newGroupResultValue));
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          currentPage: 1,
          data: { ...filtersToSearch },
          groupResult: newGroupResultValue
        })
      );
    }

    const handleReset = () => {
      dispatch(setGroupResultValues([]));
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          currentPage: 1,
          data: { ...filtersToSearch },
          groupResult: [],
        })
      );
    }

    return {
      isFilterGapsVariety,
      isFilterGlobalData,
      filters: transformFiltersATp(filtersAtp, isAccumulate, groupResultValue),
      endDate,
      startDate,
      valuesToInventoryType,
      groupResultValue,
      handleSetStartDate,
      handleSetEndDate,
      handleMaxDate,
      handleATP,
      selectedValues,
      setSelectedValues,
      setValuesToInventoryType,
      handleFilterGapsVariety,
      handleRunGlobalData,
      handleSetGroupResult,
      handleReset
    };
  };
