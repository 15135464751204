import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const headboardStyle = createNamedStyles({
  Title: {
    color: theme.palette.primary.main,
    fontSize: '1.3rem',
    fontWeight: 600,
    marginTop: '20px',
  },
});
