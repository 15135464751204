import React, { useState } from 'react';
import { Menu, MenuItem, Link, Box } from '@mui/material';
import { styled } from '@mui/system';
import { MenuCardStyles as styles } from './HoverMenu.styles';
import { Link as RouterLink } from 'react-router-dom';
import { useHoverMenu } from './state/useHoverMenuState';

const StyledMenu = styled(Menu)({
  pointerEvents: 'none',
});

const StyledMenuItem = styled(MenuItem)({
  pointerEvents: 'auto',
});

const HoverMenu = () => {
  const {
    menu,
    anchorEl,
    openMenuIndex,
    handleMenuOpen,
    handleMenuClose,
    handleSubMenuOpen,
  } = useHoverMenu();

  return (
    <Box>
      {menu.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Link
              sx={styles.link}
              component={RouterLink}
              to={item.url && item.url}
              aria-controls="main-menu"
              aria-haspopup="true"
              onMouseEnter={(event) => handleMenuOpen(event, index)}
              onMouseLeave={handleMenuClose}
            >
              {item.title}
            </Link>
            {item.items && item.items.length > 0 && openMenuIndex === index && (
              <StyledMenu
                id="main-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
              >
                <Box
                  sx={{
                    background: '#E9EAEC',
                    marginTop: '-9px',
                    marginBottom: '-8px',
                    padding: '10px',
                  }}
                >
                  {item.items.map((item:any) => { //Too abstract to set an interface
                    return (
                      <StyledMenuItem
                        key={item.title}
                        onMouseEnter={handleSubMenuOpen}
                        sx={{ color: 'black', fontSize: '0.9rem' }}
                      >
                        <Link
                          component={RouterLink}
                          underline="none"
                          to={item.url}
                        >
                          {item.title}
                        </Link>
                      </StyledMenuItem>
                    );
                  })}
                </Box>
              </StyledMenu>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default HoverMenu;
