import React, { useRef, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { theme } from '@/theme';
import Headboard from '@/components/Headboard/Headboard';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const Item = ({ sx, ...other }) => (
  <Box sx={{ color: theme.palette.text.primary, ...sx }} {...other} />
);


const Layout = ({ title, sidebar: SidebarComponent, children }) => { 
   const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleDrawer = () => setSidebarOpen(!sidebarOpen);

  return (
    <>
      <Headboard title={title} />
      <Box
        sx={{ display: 'grid', gridAutoColumns: '1fr' }}
        style={{ height: '80vh' }}
      >
        {sidebarOpen ? (
          <Item
            sx={{
              gridRow: '1',
              gridColumn: 'span 2',
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid item xs={12} md={12} sx={{ textAlign: 'end', margin: '2%' }}>
              <IconButton onClick={toggleDrawer}>
                <MenuOutlinedIcon />
              </IconButton>
            </Grid>
            <SidebarComponent />
          </Item>
        ) : (
          <Box sx={{ position: 'absolute', top: 150, left: 10, zIndex: 1000 }}>
            <IconButton onClick={toggleDrawer}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
        <Item sx={{ gridRow: '1', gridColumn: 'span 10', flexGrow: 1 }}>
          <Box sx={{ margin: sidebarOpen ? '0px 20px' : '0px 30px 0px 60px' }}>
            {children}
          </Box>
        </Item>
      </Box>
    </>
  );
};

export default Layout;
