import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Tooltip,
  Typography
} from '@mui/material';
import data from '@/shared/data/sales_orders_allocation_header.json';
import { UseSalesOrdersAllocationState } from './state/useSalesOrdersAllocationState';
import SearchBar from '@/components/SearchBar/SearchBar';
import {
  Download,
  LessIcon,
  OpenModal,
  OrderAZ,
  OrderZA,
  PlusIcon,
  SearchBarIcon,
  ShowColumns,
} from '@/shared/icons/Icons';
import { salesOrderAllocationTableStyles as styles } from './SalesOrderAllocationTable.styles';
import { SALES_ORDERS_ALLOCATION } from '@/shared/constantes/SalesOrdersAllocation';
import TooltipIcon from '@/components/Tooltip/TooltipIcon';
import { IheadCell } from '@/components/Table/TableHeader.interfaces';
import HideColumns from '@/components/HideColumns/HideColumns';
import PopoverComponent from '@/components/PopoverComponent/PopoverComponent';
import 'react-virtualized/styles.css'
import { AutoSizer, Table, Column, ScrollSync, Grid, MultiGrid } from 'react-virtualized';
import './salesOrderAllocation.css'
import 'react-virtualized/styles.css';
import { PaginationItem } from '@mui/material';
import Timer from '@/components/Timer/Timer';
import { convertNumber } from '@/shared/utils/numbersUtils';
import { ENVIRONMENT } from '@/shared/api_routes';
import { SalesOrdersAllocation } from '@/store/interfaces/salesOrdersAllocation.interfaces';

const columns: IheadCell[] = data.data;

interface CellStyleProps {
  row: any;
  dataKey: string;
}

interface IHeaderRenderer {
  columnIndex: number;
  key: string;
  style: React.CSSProperties;
}

const cellStyle = ({ row, dataKey }: CellStyleProps): React.CSSProperties => ({
  fontSize: '12px',
  padding: '14px 10px',
  backgroundColor: row.missingStems > 0
    ? (dataKey === "missingStems" ? '#FDEDEC' : (row.accountName === "TOTAL" ? '#F4F2EF' : '#FDEDEC'))
    : (row.accountName === "TOTAL" ? '#F4F2EF' : '#FFF'),
  fontFamily: 'Roboto, sans-serif',
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: row.accountName === "TOTAL" ? 700 : "",
  color: dataKey === "missingStems" && row.missingStems > 0 ? 'red' : '#5C5C5C',
  height: '31%',
  border: '1px solid #E0E0E0',
  whiteSpace: 'nowrap',
});

interface CellBreakdownsStyleProps {
  row: {
    missingStems: number;
  };
  dataKey: string;
}

const cellBreakdownsStyle = ({ row, dataKey }: CellBreakdownsStyleProps): React.CSSProperties => ({
  fontSize: '11px',
  padding: '10px 10px',
  backgroundColor: row.missingStems > 0 ? '#FDEDEC' : '#FBFAF9',
  border: '1px solid #E0E0E0',
  height: '50%',
  whiteSpace: 'nowrap',
  fontFamily: 'Roboto, sans-serif',
  textWrap: "pretty",
  color: dataKey === "missingStems" && row.missingStems > 0 ? 'red !important' : '',
});

export interface ISalesOrderAllocationTable {
  handleOpenModal: () => void;
}

interface CellRendererProps {
  columnIndex: number;
  key: string;
  rowIndex: number;
  style: React.CSSProperties;
}

interface IVisibleColumns {
  [key: string]: boolean;
}

const initializeVisibleColumns = (columns: IheadCell[]): IVisibleColumns => {
  return columns.reduce((acc: IVisibleColumns, column: IheadCell) => {
    acc[column.field] = !(column.field === 'internalId');
    return acc;
  }, {});
}

const SalesOrderAllocationTable: React.FC<ISalesOrderAllocationTable> = ({
  handleOpenModal,
}) => {
  const {
    allocations,
    sortBy,
    sortDirection,
    visible,
    loading,
    page,
    handleShowSearchBar,
    showSearchBar,
    expandedRow,
    handleShowBreakdowns,
    isExpandedAll,
    handleDownloadCsv,
    handleSubmit,
    handleSearchValue,
    adjustedRowGetter,
    adjustedRowCount,
    handleSort,
    totalAllocations,
    totalPages,
    handleChangePage,
  } = UseSalesOrdersAllocationState();

  const handleExpande = (value: string, index: number) =>
    value === 'Assorted' && (
      <IconButton
        sx={{ paddingTop: "0px", paddingBottom: "1px" }}
        data-testid="icon"
        onClick={(event) => handleShowBreakdowns(event, index)}
      >
        {!(expandedRow === index) && !isExpandedAll ? (
          <PlusIcon data-testid="plus-icon" />
        ) : (
          <LessIcon data-testid="less-icon" />
        )}
      </IconButton>
    );
  const [visibleColumns, setVisibleColumns] = useState(() => initializeVisibleColumns(columns));

  const handleToggleColumn = (columnId: string ) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: CellRendererProps) => {
    const column = visibleColumnsArray[columnIndex];
    const rowData: SalesOrdersAllocation = adjustedRowGetter({ index: rowIndex }); 
    const cellData = rowData ? rowData[column.field as keyof SalesOrdersAllocation] : null;
    const dataKey = column.field;

    const renderCellData = () => {
      if (cellData === "Assorted") {
        return (
          <>
            {cellData}
            {handleExpande(cellData, rowIndex)}
          </>
        );
      }
      if ((dataKey === 'farmDate' || dataKey === 'truckDate') && cellData != null && cellData !== "") {
        const [day, month] = typeof cellData === 'string' ? cellData.split('/') : ['', ''];
        const result = `${day}/${month}`;
        return (
          <>
            {result}
          </>
        )
      }
      if (
        dataKey === 'totalStems' ||
        dataKey === 'totalBoxes' ||
        dataKey === 'allocatedStems' ||
        dataKey === 'missingStems'
      ) {
        return (
          <>
            {convertNumber(cellData as number)}
          </>
        )
      }
      return (<>{cellData === null || cellData === undefined ? '' : String(cellData)}</>);
    };

    if (!rowData.isBreakdown) {
      return (
        <div key={key} style={style}>
          <Box
            sx={cellStyle({row: rowData, dataKey})}
          >
            {renderCellData()}
          </Box>
        </div>
      );
    }

    return (
      <div key={key} style={style}>
        <Box style={{ color: 'gray' }} sx={cellBreakdownsStyle({row:rowData, dataKey})}>
          {renderCellData()}
        </Box>
      </div>
    );
  };

  const handleOrderIcon = (field: string) => {
    if (sortBy === field) {
      return (
        <Box sx={{ marginLeft: '5px' }}>
          {sortDirection === 'asc' ? <OrderAZ /> : <OrderZA />}
        </Box>
      );
    }
    return null;
  };

  const visibleColumnsArray = columns.filter(col => visibleColumns[col.field]);
  
  const headerRenderer = ({ columnIndex, key, style }: IHeaderRenderer) => {
    const column = visibleColumnsArray[columnIndex];

    return (
      <div key={key} style={style}>
        <Box sx={styles.headerStyle} onClick={() => {
          handleSort && handleSort(column.field);
        }}>
          <Tooltip
            title={
              column.hoverName
            }
            placement="top-start"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#927C4E',
                  fontSize: '12px',
                },
              },
            }}
          >
            <span>{column.headerName}</span>
          </Tooltip>
          {handleOrderIcon(column.field)}
        </Box>
      </div>
    );
  };

  return (
    <>
      {visible && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '3px',
          }}
        >
          <Alert sx={{ width: '100%' }} severity="success">
            Viewing default sort: Guidance, Order Type and Price
          </Alert>
        </Box>
      )}
      <Box sx={styles.box}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={styles.text}>
            {`${totalAllocations && totalAllocations} results`}
          </Box>
          <Box >
            {!loading ? "" : (
              <Box sx={{ display: 'flex', justifyContent: 'start', }}>
                <CircularProgress style={{ width: "15px", height: "15px" }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar
                handleSearchValeu={handleSearchValue}
                handleSubmit={handleSubmit}
              />
            </Box>
          ) : (
            <Box style={{
              display: ENVIRONMENT.DISABLED_ICON === "true" ? 'none' : 'block'
            }}>
              <TooltipIcon
                title={SALES_ORDERS_ALLOCATION.BUTTON_SEARCH}
                handleClick={handleShowSearchBar}
                icon={<SearchBarIcon />}
              />
            </Box>
          )}
          <PopoverComponent
            children={
              <HideColumns
                columnsName={columns}
                handleToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
              />
            }
            title={SALES_ORDERS_ALLOCATION.BUTTON_HIDE}
            icon={<ShowColumns />}
          />
          <TooltipIcon
            title={SALES_ORDERS_ALLOCATION.BUTTON_EXPORT}
            icon={<Download />}
            handleClick={handleDownloadCsv}
          />
          <TooltipIcon
            title={SALES_ORDERS_ALLOCATION.BUTTON_REVENUE}
            icon={<OpenModal />}
            handleClick={handleOpenModal}
          />
        </Box>
      </Box>
      <Box style={{ height: '70vh' }}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            return (
              <ScrollSync>
                {({ onScroll, scrollLeft }) => (
                  <div style={{ height, width, position: 'relative' }}>
                    <MultiGrid
                      fixedRowCount={1}
                      fixedColumnCount={1}
                      columnCount={visibleColumnsArray.length}
                      columnWidth={({ index }: { index: number }) => visibleColumnsArray[index].width || 100}
                      height={height}
                      rowCount={adjustedRowCount + 1}
                      rowHeight={({ index }: { index: number }) => (index === 0 ? 44 : 44)}
                      width={Math.min(width)}
                      cellRenderer={({ columnIndex, key, rowIndex, style }: { columnIndex: number; key: string; rowIndex: number; style: React.CSSProperties }) => {
                        if (rowIndex === 0) {
                          return headerRenderer({ columnIndex, key, style });
                        }
                        return cellRenderer({ columnIndex, key, rowIndex: rowIndex - 1, style });
                      }}
                      style={{ overflowX: 'auto', position: 'relative' }}
                      scrollLeft={scrollLeft}
                      onScroll={onScroll}
                    />
                  </div>
                )}
              </ScrollSync>
            )
          }
          }
        </AutoSizer>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        alignItems: 'center'
      }}>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            size='small'
            style={{ display: 'flex', justifyContent: 'center' }}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                style={{
                  backgroundColor: "white",
                  fontSize: "11px",
                  padding: '0 10px',
                  textDecoration: item.selected ? 'underline' : 'none',
                  textUnderlineOffset: '4px',
                  textDecorationThickness: '2px',
                }}
              />
            )}
          >
          </Pagination>
        </Box>
        <Timer />
      </Box>
    </>
  );
};
export default React.memo(SalesOrderAllocationTable);
