import { UseIAgingByVarietyTable } from './UseAgingByVarietyTable.interfaces';
import data from '@/shared/data/aging_by_variety_header.json';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState, store } from '@/store/store';
import { useEffect, useMemo, useState } from 'react';
import { getAging } from '@/store/thunks/AgingByVariety/agingByVariety.thunks';
import { setSort } from '@/store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import { sortData } from '@/shared/utils/numbersUtils';

export const UseAgingByVarietyTableState = (): UseIAgingByVarietyTable => {
  const columns = data.data;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { loading, sortBy, sortDirection } = useAppSelector(
    (store: RootState) => store.salesOrders
  );
  const { aging } = useAppSelector((store: RootState) => store.agingByVariety);
  const dispatch = useAppDispatch();
  const [editIndex, setEditIndex] = useState<number>(0);
  const [editColumn, setEditColumn] = useState<string>('');
  const [formData, setFormData] = useState<UseIAgingByVarietyTable['formData']>({rotationDays: 0});

  useEffect(() => {
    dispatch(getAging({ dispatch }));
  }, []);

  const handleChange: UseIAgingByVarietyTable['handleChange'] = (e, columnName) => {
    setFormData({
      ...formData,
      [columnName]: e.target.value,
    });
  };

  const handleSave = () => {
    // Aquí  agregar la lógica para guardar los datos
    setEditIndex(-1);
    setEditColumn('');
  };

  const handleDoubleClick: UseIAgingByVarietyTable['handleDoubleClick'] = (rowIndex, columnName, row) => {
    setFormData(row);
    setEditIndex(rowIndex);
    setEditColumn(columnName);
  };

  const handleSort: UseIAgingByVarietyTable['handleSort'] =  (column) => {
    const newDirection =
      sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, newDirection }));
  };

  const sortAging = useMemo(() => {
    return sortData(aging, sortBy, sortDirection);
  }, [aging, sortBy, sortDirection]);

  return {
    columns,
    loading,
    aging: sortAging,
    editIndex,
    editColumn,
    formData,
    showSearchBar,
    sortBy,
    setShowSearchBar,
    handleDoubleClick,
    handleSave,
    handleChange,
    handleSort,
  };
};
