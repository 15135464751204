import 'react-datepicker/dist/react-datepicker.css';
import {
  setEndDate,
  setFilterGapsByVariety,
  setFilterHGaps,
  setFiltersToSearch,
  setSort,
  setStartDate,
} from '@/store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { UseISalesOrdersAllocationState } from './useSidebarSalesOrdesAllocation.interfaces';
import { useEffect, useRef, useState } from 'react';
import { transformFilters } from '@/store/utils/storeUtils';
import {
  getFiltersAllocationData,
  getSalesOrdersAllocation,
} from '@/store/thunks/SalesOrdersAllocation/SalesOrdersAllocation.thunks';

export const UseSidebarSalesOrdersAllocationState =
  (): UseISalesOrdersAllocationState => {
    const dispatch = useAppDispatch();
    const isFirstRender = useRef(true);
    const {
      startDate,
      endDate,
      isFilterGapsVariety,
      isFilterHGaps,
      filters,
      currentPage,
      filtersToSearch,
      sortBy,
      sortDirection
    } = useAppSelector((store: RootState) => store.salesOrders);

    const [selectedValues, setSelectedValues] = useState({});

    const handleSetStartDate = (startDate: Date) => dispatch(setStartDate(startDate));

    const handleAllocation = () => {
      let data;
      isFilterHGaps &&
        (data = {
          orderType: ['HO'],
        });
      data = { ...data, ...selectedValues };
      dispatch(
        getSalesOrdersAllocation({
          startDate,
          endDate,
          data: data,
          missingStems: isFilterGapsVariety || isFilterHGaps,
          currentPage: currentPage
        })
      );
      dispatch(getFiltersAllocationData({ startDate, endDate }));
    };

    const handleFilterGapsVariety: UseISalesOrdersAllocationState['handleFilterGapsVariety'] = (type, isDeleteFilter) => {
      const data = {
        orderType: ['HO'],
      };
      const commonParams = {
        startDate,
        endDate,
        dispatch,
        missingStems: !isDeleteFilter,
        currentPage: currentPage
      };
      const additionalParams =
        type === 'variety' || isDeleteFilter
          ? { data: selectedValues }
          : { data: { ...data, ...selectedValues } };
      dispatch(
        getSalesOrdersAllocation({ ...commonParams, ...additionalParams })
      );
      if (type === 'variety') {
        dispatch(setFilterGapsByVariety(!isDeleteFilter));
        dispatch(setFilterHGaps(false));
      } else {
        dispatch(setFilterHGaps(!isDeleteFilter));
        dispatch(setFilterGapsByVariety(false));
      }
    };

    const handleSetEndDate = (endDate: Date) => dispatch(setEndDate(endDate));

    const handleMaxDate = () => {
      const date = new Date();
      date.setDate(date.getDate() + 14);
      return date;
    };

    const handleDefaultSort = () => {
      dispatch(setSort({ column: null, newDirection: 'asc' }));
      dispatch(
        getSalesOrdersAllocation({
          startDate,
          endDate,
          currentPage: currentPage
        })
      );
    };

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      let data;
      isFilterHGaps &&
        (data = {
          orderType: ['HO'],
        });
      data = { ...data, ...selectedValues };
      dispatch(getSalesOrdersAllocation({
        startDate,
        endDate,
        data: data,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        currentPage: currentPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      }))
      dispatch(setFiltersToSearch(selectedValues));
    }, [selectedValues]);

    useEffect(() => {
      setSelectedValues(filtersToSearch);
    }, []);

    return {
      filters: transformFilters(filters),
      endDate,
      startDate,
      isFilterGapsByVariety: isFilterGapsVariety,
      isFilterHGaps,
      selectedValues,
      handleSetStartDate,
      handleAllocation,
      handleSetEndDate,
      handleMaxDate,
      handleFilterGapsVariety,
      setSelectedValues,
      handleDefaultSort,
    };
  };
