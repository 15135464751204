import { createSlice } from '@reduxjs/toolkit';
import { AvailableToPromisSlice } from '../interfaces/availableToPromise.interfaces';
import {
  getATP,
  getFiltersATP,
} from '../thunks/AvailableToPromise/availableToPromise.thunks';

export const initialState: AvailableToPromisSlice = {
  startDate: new Date(),
  endDate: new Date(),
  atpData: {
    atp: [],
    totalAtp: 0,
    currentPage: 1,
    totalPages: 1,
  },
  filtersAtp: {
    group: [],
    datesType: [],
    category: [],
    variety: [],
    color: [],
    grade: [],
  },
  filtersInventoryTypes: {
    inventoryType: [],
  },
  isAccumulate: false,
  isFilterGlobalData: true,
  totalPages: 0,
  groupResultValue: [],
};
export const availableToPromiseSlice = createSlice({
  name: 'AvailableToPromiseSlice',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setFiltersInventoryTypes: (state, action) => {
      state.filtersInventoryTypes = action.payload;
    },
    setIsAccumulate: (state, action) => {
      state.isAccumulate = action.payload;
    },
    setFilterGlobalData: (state, action) => {
      state.isFilterGlobalData = action.payload;
    },
    setGroupResultValues: (state, action) => {
      state.groupResultValue = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getATP.fulfilled, (state, { payload }) => {
        state.atpData = payload;
        state.totalPages = payload.totalPages;
      })
      .addCase(getFiltersATP.fulfilled, (state, { payload }) => {
        state.filtersAtp = payload;
      });
  },
});
export default availableToPromiseSlice.reducer;
