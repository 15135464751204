// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.centered-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .tabs-root {
    color: black;
  }
  
  .tabs-root .Mui-selected {
    color: black !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AtpSourceDataTab/AtpSourceDataTab.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":["\n.centered-box {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n  }\n  \n  .tabs-root {\n    color: black;\n  }\n  \n  .tabs-root .Mui-selected {\n    color: black !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
