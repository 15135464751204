import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import { IProtectedRouteProps } from './ProtectedRoute.inteface';



const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ element, ...rest }) => {
  const { instance } = useMsal();
  const activeAccount: any = instance.getActiveAccount();

  return activeAccount ? element : <Navigate to="/" />;
};
export default ProtectedRoute;
