export const ATP_SOURCE_DATA = {
  TITLE: 'ATP Source Data',
  DOWNLOAD: 'Export',
  BUTTON_ACTIONS: 'Quick Actions',
  VARIETY_ACTION: 'See Gaps by Variety',
  ACCUMULATE_INVENTORY: 'Combine days',
  GLOBAL_DATA_ACTION: 'Run Global Data',
  BUTTON_HIDE: 'Hide',
  BUTTON_SEARCH: 'Search',
  TOTAL: 'TOTAL',
};
