import {
  MenuEnum,
  REVENUE_MANAGEMENT_MENU_ENUM,
} from '@/shared/constantes/MenuEnum';
import { useState } from 'react';
import { IUseHoverMenu } from './useHoverMenu.interfaces';

export const useHoverMenu = (): IUseHoverMenu => {
  const menu = [
    {
      title: MenuEnum.FORECAST_MANAGEMENT,
    },
    {
      title: MenuEnum.REVENUE_MANAGEMENT,
      items: [
        {
          title: REVENUE_MANAGEMENT_MENU_ENUM.AVALIBLE_TO_PROMISE,
          url: '/revenue-management/available-to-promise',
        },
        {
          title: REVENUE_MANAGEMENT_MENU_ENUM.SALES_ORDERS_ALLOCATION,
          url: '/revenue-management/sales-order-allocation',
        },
       /* {
          title: REVENUE_MANAGEMENT_MENU_ENUM.ATP_SOURCE_DATA,
          url: '/revenue-management/atp-source-data',
        },
        {
          title: REVENUE_MANAGEMENT_MENU_ENUM.AGING_VARIEY,
          url: '/revenue-management/aging-by-variety',
        },
        {
          title: REVENUE_MANAGEMENT_MENU_ENUM.ALLOCATION_EXCEPTIONS,
        },
        {
          title: REVENUE_MANAGEMENT_MENU_ENUM.ALLOCATION_PRIORITIES,
        },*/
      ],
    },
    {
      title: MenuEnum.SALES_MANAGEMENT,
      url: '/sales-management/home',
    },
    {
      title: MenuEnum.WAREHOUSE_MANAGEMENT,
      url: 'ware',
    },
  ];
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  let closeMenuTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

  const handleMenuOpen: IUseHoverMenu['handleMenuOpen'] = (event, index) => {
    clearTimeout(closeMenuTimeout);
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleMenuClose = () => {
    closeMenuTimeout = setTimeout(() => {
      setAnchorEl(null);
      setOpenMenuIndex(null);
    }, 200);
  };

  const handleSubMenuOpen = () => {
    clearTimeout(closeMenuTimeout);
  };

  return {
    anchorEl,
    menu,
    openMenuIndex,
    handleMenuOpen,
    handleMenuClose,
    handleSubMenuOpen,
  };
};
