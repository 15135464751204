import { createSlice } from '@reduxjs/toolkit';
import { SalesOrderLocalState } from '../interfaces/salesOrdes.interfaces';
import {
  getFiltersAllocationData,
  getSalesOrdersAllocation,
} from '../thunks/SalesOrdersAllocation/SalesOrdersAllocation.thunks';

export const initialState: SalesOrderLocalState = {
  allocation: {
    allocations: [],
    totalAllocations: 0,
    totalEarnings: '',
    totalFulfilledAllocations: 0,
    totalMaxEarnings: '',
    currentPage: 1,
    totalPages: 1,
  },
  currentPage: 1,
  totalPages: 0,
  startDate: (() => {
    const date = new Date();
    return date;
  })(),
  endDate: (() => {
    const date = new Date();
    // date.setDate(date.getDate() + 1)
    return date;
  })(),
  loading: false,
  isFilterGapsVariety: false,
  isFilterHGaps: false,
  filters: {
    accountName: [],
    accountOwner: [],
    boxType: [],
    color: [],
    flowerSpecies: [],
    grade: [],
    guidance: [],
    market: [],
    orderType: [],
    variety: [],
    category: [],
    boxComposition: [],
  },
  filtersToSearch: {
    inventoryType: [],
  },
  sortBy: null,
  sortDirection: 'asc',
};

export const salesOrdersSlice = createSlice({
  name: 'salesOrdersSlice',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilterGapsByVariety: (state, action) => {
      state.isFilterGapsVariety = action.payload;
    },
    setFilterHGaps: (state, action) => {
      state.isFilterHGaps = action.payload;
    },
    setFiltersToSearch: (state, action) => {
      state.filtersToSearch = action.payload;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload.column;
      state.sortDirection = action.payload.newDirection;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSalesOrdersAllocation.fulfilled, (state, { payload }) => {
        state.allocation = {
          ...state.allocation,
          allocations: payload.allocations,
          totalAllocations: payload.totalAllocations,
        };
        state.totalPages = payload.totalPages;
      })
      .addCase(getFiltersAllocationData.fulfilled, (state, { payload }) => {
        state.filters = payload;
      });
  },
});

export default salesOrdersSlice.reducer;
