import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const MenuCardStyles = createNamedStyles({
  link: {
    margin: 2,
    color: 'white',
    fontSize: '1rem',
    textDecorationColor: '${(props) => props.theme.palette.background}',
  },
});
