import React from 'react';
import { hideColumnsStyle as styles } from './HideColumns.style';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  Typography,
} from '@mui/material';

interface IHeaderColumn {
  hoverName: string,
  field: string,
  headerName: string
}

interface IHideColumns{
  columnsName: IHeaderColumn[],
  handleToggleColumn: (columnId: string) => void,
  visibleColumns: {[key: string]: boolean},
}

const HideColumns: React.FC<IHideColumns> = ({ columnsName, handleToggleColumn, visibleColumns }) => {
  
  return (
    <Box sx={{ width: '200px' }}>
      <Box sx={styles.box}>Default Columns</Box>
      <List>
        <FormGroup>
          {columnsName.map((headerColumn: IHeaderColumn) => {
            return (
              <FormControlLabel
                key={headerColumn.field}
                value={headerColumn.field}
                control={<Checkbox />}
                name={headerColumn.field}
                label={<Typography>{headerColumn.hoverName}</Typography>}
                checked={visibleColumns[headerColumn.field]}
                onChange={() => handleToggleColumn(headerColumn.field)}
                sx={{ marginLeft: '0px' }}
              />
            );
          })}
        </FormGroup>
      </List>
    </Box>
  );
};
export default HideColumns;
