import { formatDate } from './dateUtils';

export const convertNumber = (number: number, locale = 'en-US') => {
  return number ? number.toLocaleString(locale) : 0;
};

export const sortData = (
  data: any[], //Too abroad for defining an interface
  sortBy: string | null,
  sortDirection: string,
  inventory?: boolean,
  dates?: string[]
) => {
  let atpWithTotalATP;
  if (sortBy === 'total' && dates) {
    atpWithTotalATP = data.map((atp) => {
      const total = dates.reduce((acc, date) => {
        return acc + (atp.inventory[date] ? atp.inventory[date].atp : 0);
      }, 0);
      return { ...atp, totalAtp: total };
    });
  }
  const newData = sortBy === 'total' ? atpWithTotalATP : data;
  if (newData && newData.length > 0 && sortBy !== null) {
    const sorted = [...newData].sort((a, b) => {
      let avalue = a[sortBy];
      let bvalue = b[sortBy];

      if (inventory) {
        avalue = a.inventory[sortBy]?.atp || 0;
        bvalue = b.inventory[sortBy]?.atp || 0;
      }

      if (sortBy === 'total') {
        avalue = a.totalAtp;
        bvalue = b.totalAtp;
      }

      if (avalue === undefined || avalue === '') return 1;
      if (bvalue === undefined || bvalue === '') return -1;

      avalue = sortBy === 'grade' ? Number(avalue) : avalue;
      bvalue = sortBy === 'grade' ? Number(bvalue) : bvalue;

      if (!isNaN(avalue) && !isNaN(bvalue)) {
        if (avalue < bvalue) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (avalue > bvalue) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      } else {
        const aString = String(avalue);
        const bString = String(bvalue);
        return (
          aString.localeCompare(bString) * (sortDirection === 'asc' ? 1 : -1)
        );
      }
    });
    return sorted;
  }
  return data;
};

export const columnsDate = (startDate: Date, endDate: Date): any[] => {
  //Result is too abroad for defining an interface
  const dates: string[] = [];
  const currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  while (currentDate <= endDate) {
    const newformat = formatDate(currentDate);
    dates.push(newformat);
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);
  }
  return dates;
};
