import React, { useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import { filterStyle as style } from './Filter.styles';
import { ITransformFilters } from '@/store/interfaces/store.interfaces'


interface FilterComponentProps {
  data: any; //Filter's data might be different for the revenueManagement's containers
  selectedValues: any; //Filter's data might be different for the revenueManagement's containers
  setSelectedValues: (newState: any) => void; //Too abstract to know what is newState, this is spected for a generic component
  showHeader: boolean;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  data,
  selectedValues,
  setSelectedValues,
  showHeader,
}) => {
  const [filterValues, setFilterValues] = useState<{ [key: number]: string[] }>([]);
  const [filterInput, setFilterInputs] = useState<{ [key: string]: string }>({});

  const handleSelecteAll = (filter: ITransformFilters) => {
    const newSelectedValue = { ...selectedValues };
    newSelectedValue[filter.id] = filter.valueOptions.slice();
    setSelectedValues(newSelectedValue);
  };

  const handleClear = (filter: ITransformFilters) => {
    const newSelectedValue = { ...selectedValues };
    newSelectedValue[filter.id] = [];
    setSelectedValues(newSelectedValue);
  };
  const handleClearAll = () => {
    setSelectedValues([]);
    setFilterInputs({});
    setFilterValues({});
  };

  const handleChange = (type: string, event: React.SyntheticEvent<Element, Event>, filter: ITransformFilters) => {
    const inputElement = event.currentTarget.closest('input'); // Find the input element
    if (inputElement) {
      const newSelectedValue = { ...selectedValues };
      const target = event.target as HTMLInputElement;

      if (target.checked) {
        if (type == 'RadioButton') {
          newSelectedValue[filter.id] = [];
          newSelectedValue[filter.id].push(inputElement.value);
        } else {
          if (!newSelectedValue[filter.id]) {
            newSelectedValue[filter.id] = [];
          }
          newSelectedValue[filter.id] = [
            ...newSelectedValue[filter.id],
            inputElement.value,
          ];
        }
      } else {
        newSelectedValue[filter.id] = newSelectedValue[filter.id].filter(
          (val: string) => val !== inputElement.value
        );
      }
      setSelectedValues(newSelectedValue);
    }
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = event.target.value;
    setFilterInputs((prev) => ({ ...prev, [index]: value }));
    const newValueOptions: string[] = data[index]['valueOptions'];
    const newFilterValues = newValueOptions.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setFilterValues((prev) => ({
      ...prev,
      [index]: newFilterValues,
    }));
  };

  return (
    <>
      {showHeader && (
        <Box
          sx={{ margin: '5px', backgroundColor: 'white', padding: '2px' }}
        ></Box>
      )}
      {showHeader && (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button sx={style.textButton} onClick={handleClearAll}>
            Clear Filters
          </Button>
        </Box>
      )}
      <div style={{ maxHeight: data.length > 5 ? '50vh' : '30vh', overflowY: 'auto', margin: "0px" }}>
        {data.map((filter: ITransformFilters, index: number) => (
          <Accordion key={`filter.id_${index}`} style={{ margin: "0px" }}>
            <AccordionSummary
              sx={style.filterSummary}
              expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
              aria-controls="panel1-content"
              id={filter.id}
            >
              <Typography sx={style.text}>{filter.filtername}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={style.accordionDetails}>
              {(filter.id === 'accountName' || filter.id === 'variety') && (
                <TextField
                  data-testid="searchBar"
                  id={`search-bar-${index}`}
                  variant="outlined"
                  placeholder={`Search by ${filter.filtername}`}
                  size="small"
                  value={filterInput[index] || ''}
                  onChange={(event) => handleFilter(event, index)}
                  sx={style.searchBar}
                />
              )}
              {filter.valueOptions.length > 4 && (
                <Box sx={style.box}>
                  <div onClick={() => handleSelecteAll(filter)}>Select All</div>
                  <div onClick={() => handleClear(filter)}>Clear All</div>
                </Box>
              )}
              <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
                <RadioGroup>
                  {(Array.isArray(filterValues[index] || filter.valueOptions) ? filterValues[index] || filter.valueOptions : []).map((item: string) => (
                    <FormControlLabel
                      key={item}
                      value={item}
                      control={
                        filter.componentType == 'RadioButton' ? (
                          <Radio />
                        ) : (
                          <Checkbox />
                        )
                      }
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          {item}
                        </Typography>
                      }
                      checked={
                        selectedValues[filter.id]
                          ? selectedValues[filter.id].includes(item)
                          : false
                      }
                      onChange={(event) =>
                        handleChange(filter.componentType, event, filter)
                      }
                      disabled={filter.disabled}
                    />
                  ))}
                </RadioGroup>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};
export default FilterComponent;
